const baseUrl = 'https://findfalcone.herokuapp.com/';
const planetUrl = `${baseUrl}planets`;
const vehicleUrl = `${baseUrl}vehicles`;
const tokenUrl = `${baseUrl}token`;
const searchUrl = `${baseUrl}find`;

const maxOptionsToAllow = 4;

const allowSendingLessThanMax = false;

export default {
  planetUrl,
  vehicleUrl,
  tokenUrl,
  searchUrl,
  maxOptionsToAllow,
  allowSendingLessThanMax,
};
