import React from 'react';
import './footer.css';

const Footer = () => (
  <footer className="footer">
    <strong>You&rsquo;ve reached the footer</strong>
  </footer>
);

export default Footer;
