import React from 'react';
import Dropdown from '../dropdown';
import Radio from '../radio';
import './planet-selector.css';

const moveSelectorParent = (name) => {
  const element = document.querySelector(`#${name}`);
  if (element.nextElementSibling) {
    const { width, x } = element.nextElementSibling.getBoundingClientRect();
    element.parentElement.scrollLeft += x - width / 2; // todo, make scroll smoother so that it is evident that the element has scrolled.
  }
};

const stateClasses = (vehicles = [], selectedPlanet, selectedVehicle) => {
  if (selectedPlanet && selectedVehicle) {
    return 'selected'; // selector has valid planet and selector
  } if (!!vehicles && !!vehicles.length && vehicles.filter(vehicle => !vehicle.disabled && !vehicles.numberLeft).length < 1) {
    return 'unassignable'; // selector cannot be assigned a valid vehicle
  }
  return 'pending'; // selector has to be assigned a planet/vehicle
};

const PlanetSelector = ({
  name,
  label = name,
  planets,
  onPlanetSelect = () => {},
  selectedPlanet,
  vehicles,
  onVehicleSelect = () => {},
  selectedVehicle,
}) => (
  <div className={`planet-selector__container ${stateClasses(vehicles, selectedPlanet, selectedVehicle)}`} id={name}>
    {label}
    <Dropdown
      options={planets}
      onChange={value => onPlanetSelect(value)}
      name={name}
    />
    <Radio
      selectedPlanet={selectedPlanet}
      selectedVehicle={selectedVehicle}
      options={vehicles}
      name={name}
      onChange={(value, isMouse) => { if (isMouse) moveSelectorParent(name); onVehicleSelect(value); }}
    />
  </div>
);

export default PlanetSelector;
