const makeRequest = (url, params = {}, method = 'GET', headers = {}) => fetch(url, {
  method,
  headers: { ...headers },
  body: method === 'GET' ? null : JSON.stringify(params),
}).then((r) => {
  if (!r.ok) {
    throw new Error(r);
  } else {
    return r.json();
  }
});

export default makeRequest;
