import React from 'react';
import './loader.css';

const Loader = ({
  message = 'Please Wait...',
}) => (
  <>
    <div className="loader" />
    <span>{message}</span>
  </>
);

export default Loader;
