import React from 'react';
import Loader from '../../components/loader/index';
import PlanetSelector from '../../components/planet-selector';
import makeRequest from '../../helpers/api';
import './home.css';
import variables from '../../variables';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planets: [],
      vehicles: [],
      networkRequest: false,
      error: false,
      maxAllowedPlanets: variables.maxOptionsToAllow, // number of options to allow
      planetSelections: {},
      vehicleSelections: {},
      message: "Getting Information about Falcone's whereabouts",
    };
  }

  componentDidMount = () => {
    this.initialize();
  }

  initialize = () => {
    this.setState({
      networkRequest: true,
      error: false,
    });
    Promise.all([
      this.getPlanets(),
      this.getVehicles(),
    ])
      .then(() => this.setState({
        networkRequest: false,
      }))
      .catch(e => this.setState({
        networkRequest: false,
        error: e,
        retryAction: this.initialize,
      }));
  }

  getPlanets = () => makeRequest(variables.planetUrl)
    .then((planets) => {
      this.setState({
        planets,
      });
    })
    .catch(() => {
      throw new Error("Couldn't fetch planets");
    })

  getVehicles = () => makeRequest(variables.vehicleUrl)
    .then((vehicles) => {
      this.setState({
        vehicles,
      });
    })
    .catch(() => {
      throw new Error("Couldn't fetch vehicles");
    })

  isPlanetDisabled = (planet, selector) => this.state.planetSelections[selector] !== planet.name && Object.values(this.state.planetSelections).includes(planet.name)

  isVehicleDisabled = (vehicle, planet, selector) => {
    if (!planet) {
      return false;
    }
    return (vehicle.max_distance < planet.distance || this.howManyVehiclesAvailable(vehicle) < 1) && this.state.vehicleSelections[selector] !== vehicle.name;
  }

  howManyVehiclesAvailable = (vehicle) => {
    const vehiclesSelected = Object.values(this.state.vehicleSelections);
    if (vehiclesSelected.sort().indexOf(vehicle.name) !== -1) {
      return Math.max(0, vehicle.total_no + vehiclesSelected.indexOf(vehicle.name) - vehiclesSelected.lastIndexOf(vehicle.name) - 1);
    }
    return vehicle.total_no;
  }

  findAvailablePlanets = selector => this.state.planets.map(planet => ({ name: planet.name, distance: planet.distance, disabled: this.isPlanetDisabled(planet, selector) }))

  findAvailableVehicles = (selector, planetName) => {
    const planet = this.state.planets.find(planetItem => planetItem.name === planetName);
    if (!planet) {
      return [];
    }
    return this.state.vehicles.map(vehicle => ({ name: vehicle.name, numberLeft: this.howManyVehiclesAvailable(vehicle), disabled: this.isVehicleDisabled(vehicle, planet, selector) }));
  }

  assignPlanet = (selector, planet) => {
    const { planetSelections, vehicleSelections } = this.state;
    planetSelections[selector] = planet;
    delete vehicleSelections[selector];
    this.setState({
      planetSelections,
    });
  }

  assignVehicle = (selector, vehicle) => {
    const { vehicleSelections } = this.state;
    vehicleSelections[selector] = vehicle;
    this.setState({
      vehicleSelections,
    });
  }

  createSelectors = () => {
    const selectors = [];
    for (let i = 0; i < Math.min(this.state.maxAllowedPlanets, this.state.planets.length); i++) {
      const planetName = `Planet${i}`;
      selectors.push(<PlanetSelector
        label={`Planet ${i + 1}`}
        name={planetName}
        key={planetName}
        planets={this.findAvailablePlanets(planetName)}
        vehicles={this.findAvailableVehicles(planetName, this.state.planetSelections[planetName])}
        selectedPlanet={this.state.planetSelections[planetName]}
        selectedVehicle={this.state.vehicleSelections[planetName]}
        onPlanetSelect={planet => this.assignPlanet(planetName, planet)}
        onVehicleSelect={(vehicle => this.assignVehicle(planetName, vehicle))}
      />);
    }
    return selectors;
  }

  enableSendButton = () => {
    const { allowSendingLessThanMax } = variables; // to allow/disallow sending less than the max number of selections
    const planetsChosen = Object.values(this.state.planetSelections).length;
    const vehiclesChosen = Object.values(this.state.vehicleSelections).length;
    return planetsChosen === vehiclesChosen && (planetsChosen === this.state.maxAllowedPlanets || (allowSendingLessThanMax && planetsChosen > 0));
  }

  getToken = () => {
    const headers = {};
    headers.Accept = 'application/json';
    return makeRequest(variables.tokenUrl, {}, 'POST', headers)
      .catch(() => {
        throw new Error("Couldn't get token");
      });
  }

  sendVehicles = (token) => {
    const headers = {}; const
      params = {};
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    params.token = token;
    params.planet_names = Object.values(this.state.planetSelections);
    params.vehicle_names = Object.values(this.state.vehicleSelections);
    return makeRequest(variables.searchUrl, params, 'POST', headers)
      .catch(() => {
        throw new Error("Couldn't find Falcone");
      });
  }

  handleSendButtonClick = () => {
    this.setState({
      networkRequest: true,
      message: 'Looking for Falcone',
      error: false,
    });
    this.getToken()
      .then(tokenResponse => this.sendVehicles(tokenResponse.token))
      .then((searchResponse) => {
        this.setState({
          networkRequest: false,
        });
        this.props.history.push(`/result?result=${searchResponse.status}${searchResponse.status === 'success' ? `&planetName=${searchResponse.planet_name}&time=${this.timeTaken()}` : ''}`);
      })
      .catch(e => this.setState({
        networkRequest: false,
        error: e,
        retryAction: this.handleSendButtonClick,
      }));
  }

  timeTaken = () => Object.values(this.state.planetSelections).reduce((time, planetName, index) => {
    const planet = this.state.planets.find(planetItem => planetItem.name === planetName);
    const vehicle = this.state.vehicles.find(vehicleItem => vehicleItem.name === Object.values(this.state.vehicleSelections)[index]);
    if (!!planet && !!vehicle) {
      return time + (planet.distance / vehicle.speed);
    }
    return time + 0;
  }, 0)

  render = () => (
    <div className="main-section">
      <h2>
          Where do you want to search for Falcone?
      </h2>
      {
          (!this.state.networkRequest && this.state.vehicles.length > 0 && this.state.planets.length > 0)
          && (
            <div className="selectors">
              {this.createSelectors()}
            </div>
          )
        }
      {
          this.state.networkRequest
          && (
            <Loader message={this.state.message} />
          )
        }
      {
          this.state.error
            ? (
              <div className="error-state">
                {this.state.error.message}
                <br />
                <button type="button" onClick={this.state.retryAction}>Retry</button>
              </div>
            )
            : (
              <div className="actions">
                Time taken:
                {' '}
                {this.timeTaken()}
                {' '}
                hours
                <br />
                <button type="button" className={`${this.enableSendButton() ? '' : 'disabled'}`} onClick={this.handleSendButtonClick} disabled={!this.enableSendButton()}>Find Falcone!</button>
              </div>
            )
        }
    </div>
  )
}

export default Home;
