import React from 'react';
import './radio.css';

const Radio = ({
  options,
  onChange = () => {},
  name,
  selectedPlanet,
  selectedVehicle,
}) => (
  <div className="radio__container">
    {
      options.map((option, index) => (
        <div key={`${selectedPlanet}${name}${option.name}`}>
          <label className={`radio__option ${option.disabled ? 'radio__disabled' : ''}`} htmlFor={`${name}${index}`}>
            <input name={name} defaultChecked={selectedVehicle === option.name} id={`${name}${index}`} type="radio" onClick={event => onChange(event.target.value, event.clientX > 0)} disabled={option.disabled} value={option.name} />
            {option.name}
            (
            {option.numberLeft}
            )
          </label>
          <br />
        </div>
      ))
    }
  </div>
);

export default Radio;
