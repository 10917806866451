import React from 'react';
import './dropdown.css';

const Dropdown = ({
  options,
  onChange = () => {},
  name,
}) => (
  <div className="dropdown__container">
    <select
      name={name}
      defaultValue={0}
      onChange={event => onChange(event.target.value)}
    >
      <option value={0} disabled>Select a planet</option>
      {
        options.map(option => <option key={option.name} disabled={option.disabled} value={option.name}>{option.name}</option>)
      }
    </select>
  </div>
);

export default Dropdown;
