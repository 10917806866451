import React from 'react';
import { Link } from 'react-router-dom';
import './not-found.css';

const NotFound = () => (
  <div className="not-found">
    You look lost. Let&rsquo;s go back
    {' '}
    <Link to="/">home</Link>
  </div>
);

export default NotFound;
