import React from 'react';
import { Link } from 'react-router-dom';

const Result = ({
  location,
}) => {
  const params = {};
  location.search.replace('?', '').split('&').forEach((param) => {
    const [key, value] = param.split('=');
    params[key] = value;
  });
  return (
    <div className="result">
      {
      // eslint-disable-next-line no-nested-ternary
      params.result === 'success'
        ? (
          params.planetName
            ? (
              <h2>
                {`You found Falcone at ${params.planetName}! ${params.time ? `And it only took you ${params.time} hours!` : ''}`}
              </h2>
            )
            : (
              <h2>
                Looks like we messed up. Something is off with the results.
              </h2>
            )
        )
        : (
          <h2>
            Looks like she escaped :(
          </h2>
        )
    }
      <br />
      <Link to="/">Try again?</Link>
    </div>
  );
};

export default Result;
