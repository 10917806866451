import React from 'react';
import './header.css';

const Header = () => (
  <header className="header">
    <h1>Finding Falcone</h1>
  </header>
);

export default Header;
